%primary-font {
	font-family: "Roboto";
}
%secondary-font {
	font-family: "Poppins", sans-serif;
}

%left {
	float: left;
}
%right {
	float: right;
}

%v-align {
	display: inline-block;
	vertical-align: middle;
}

%clearfix {
	*zoom: 1;
	&:before,
	&:after {
		content: " ";
		display: table;
	}
	&:after {
		clear: both;
	}
}

%flexbox {
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flexbox;
	display: flex;
}
%inline-flexbox {
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: -moz-inline-box;
	display: inline-flex;
}
