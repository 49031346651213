.black {
	color: $black !important;
}
.primary-text-color {
	color: $primary-text-color !important;
}
.ml-auto {
	margin-left: auto;
}
.text-center {
	text-align: center;
}
.text-right {
	text-align: right;
}
.p-40 {
	padding: 40px;
}
.w-100 {
	width: 100%;
}
h1.title1 {
	font-weight: normal;
	font-size: 24px;
	line-height: 28px;
}
h6.title6 {
	font-weight: bold;
	font-size: 17px;
	line-height: 21px;
}
p.sub-text {
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.005em;
	color: $secondary-text-color;
}
.link {
	font-weight: 700;
	color: $primary-brand-color;
	transition: all 0.3s ease;
	&:hover {
		color: darken($primary-brand-color, 10%);
		// color: $primary-brand-color-dark;
	}
}
.section-body {
	height: calc(100vh - 47px);
	background: $background-color;
	padding: 35px 30px;
	overflow-y: auto;
}
.bread-crumbs {
	a {
		font-weight: bold;
		color: #000000;
		&:not(:last-child) {
			&::after {
				content: "/";
				margin: 5px;
			}
		}
		&.active {
			color: $primary-brand-color;
			pointer-events: none;
		}
	}
}
.card {
	min-height: 275px;
	background: $white;
	border: 1px solid #f2f2f2;
	border-radius: 4px;
}
.label-value-wrap {
	.label {
		color: $grey;
		margin-bottom: 6px;
	}
	.value {
		font-size: 16px;
		line-height: 19px;
	}
}
.password-strength-text {
	li {
		color: $grey;
		&::before {
			content: "\E40C";
			display: inline-block;
			color: #ccc;
			margin-right: 5px;
			font-family: "Material Icons";
			font-size: 16px;
			position: relative;
			top: 3px;
		}
		&.valid {
			&::before {
				content: "\E86C";
				color: $green;
			}
		}
	}
	&.error {
		li {
			color: $secondary-brand-color;
		}
	}
}
.form-control-custom,
input.profile__datepicker {
	border: 1px solid #bdbdbd;
	box-sizing: border-box;
	border-radius: 2px;
	height: 32px;
	padding-left: 10px;
	width: 100%;
	&:focus {
		border-color: #0077bf;
	}
}

a {
	&.disabled {
		pointer-events: none;
		opacity: 0.5;
	}
}

.custom-select {
	width: 100%;
	padding: 5px 10px;
	font-size: 14px;
	line-height: 22px;
	border: 1px solid #bfbebe;
	border-radius: 2px;
	background: #fff url("./../../assets/images/dropdown.svg") right 15px center
		no-repeat;
	-webkit-appearance: none;
	height: 32px;
	padding-right: 30px;
	&:focus {
		border-color: #0077bf;
	}
	&:disabled {
		opacity: 0.5;
		pointer-events: none;
	}
}

p.form-group-error {
	color: red;
	font-weight: normal;
	padding-top: 5px;
	text-align: left;
}

button {
	&:disabled {
		opacity: 0.5;
		cursor: default;
		pointer-events: none;
	}
}
.pos-relative {
	position: relative;
}
.success-message {
	color: #00a558 !important;
}
.error-message {
	color: red !important;
}
.border-bottom-1 {
	border-bottom: 1px solid #dbdbdb;
}
.p-0 {
	padding: 0 !important;
}
.line-height-35 {
	line-height: 35px;
}
.modal-custom {
	.modal {
		position: fixed;
		top: 0;
		left: 0;
		z-index: 1050;
		display: none;
		width: 100%;
		height: 100%;
		overflow: hidden;
		outline: 0;
		.modal-container {
			position: absolute;
			max-width: 500px;
			background: #ffffff;
			margin: auto;
			transform: none;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			.modal-header {
				padding: 10px 25px;
				border-bottom: 1px solid #e5e5e5;
				h3 {
					color: #211f1f;
					position: relative;
					top: 5px;
					font-size: 16px;
					font-weight: 500;
				}
				.modal-close {
					img {
						cursor: pointer;
					}
				}
			}
			.modal-body {
				padding: 20px 25px;
				.form-group {
					margin-bottom: 20px;
					label {
						padding-bottom: 10px;
					}
					input,
					select {
						font-weight: 500;
						color: #363636;
						font-size: 14px;
					}
				}
				.btn {
					height: 35px;
					&.mw-100 {
						min-width: 100px;
					}
				}
			}
		}
	}
}
.add-more-button,
.remove-btn {
	font-weight: 500;
	font-size: 14px;
	line-height: 16px;
	text-decoration-line: underline;
	color: #f93d49;
}

.custom-checkbox {
	position: absolute;
	opacity: 0;
	& + label {
		position: relative;
		cursor: pointer;
		padding: 0;
	}
	& + label:before {
		content: "";
		margin-right: 10px;
		display: inline-block;
		vertical-align: text-top;
		width: 20px;
		height: 20px;
		background: white;
		border: 2px solid #f93d49;
	}
	&:hover + label:before {
		opacity: 0.9;
	}
	&:focus + label:before {
		box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
	}
	&:checked + label:before {
		background: #f35429;
	}
	// Disabled state label.
	&:disabled + label {
		color: #b8b8b8;
		cursor: auto;
	}
	// Disabled box.
	&:disabled + label:before {
		box-shadow: none;
		background: #ddd;
	}
	&:checked + label:after {
		content: "";
		position: absolute;
		left: 5px;
		top: 9px;
		background: white;
		width: 2px;
		height: 2px;
		box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white,
			4px -6px 0 white, 4px -8px 0 white;
		transform: rotate(45deg);
	}
}
.no-records {
	border: 1px solid #dbdbdb;
	padding: 30px;
	text-align: center;
}

.switch {
	position: relative;
	display: inline-block;
	width: 45px;
	height: 19px;
	input {
		opacity: 0;
		width: 0;
		height: 0;
		&:checked + .slider {
			background-color: #00a558;
		}
		&:focus + .slider {
			box-shadow: 0 0 1px #00a558;
		}
		&:checked + .slider:before {
			-webkit-transform: translateX(26px);
			-ms-transform: translateX(26px);
			transform: translateX(26px);
		}
	}
	.slider {
		position: absolute;
		cursor: pointer;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: #d3d3d3;
		-webkit-transition: 0.4s;
		transition: 0.4s;
		&:before {
			position: absolute;
			content: "";
			height: 12px;
			width: 12px;
			left: 4px;
			bottom: 4px;
			top: 4px;
			background-color: white;
			transition: 0.4s;
		}
		&.round {
			border-radius: 24px;
			&::before {
				border-radius: 50%;
			}
		}
	}
}

.table-body {
	max-height: 300px;
	overflow-y: auto;
	margin-right: 17px;
}

.error {
	color: red !important;
	font-weight: normal;
}

.no-record {
	height: 300px;
	display: flex;
	justify-content: center;
	align-items: center;
	background: #fff;
	font-size: 16px;
	font-weight: 500;
}

.modal-close i {
	cursor: pointer;
}
.pos-relative {
	position: relative;
}
.add-on-plus {
	position: absolute;
	background: #f5f5f5;
	top: 0;
	width: 30px;
	height: 34px;
	text-align: center;
	font-size: 20px;
	border: 1px solid rgba(154, 157, 158, 0.58);
	color: initial;
}

.custom-mobile-section {
	.country-label {
		min-width: 30px;
		border: 1px solid #bdbdbd;
		border-width: 1px 0;
		background: #f5f5f5;
		height: 34px;
		vertical-align: middle;
		position: absolute;
		line-height: 30px;
		text-align: center;
		font-weight: normal;
	}
	.mobile-input {
		input {
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
			height: 34px;
		}
	}
	.isd-input {
		input {
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
			height: 34px;
		}
	}
}
.cancel--btn {
	min-width: 100px;
}
.error {
	font-size: 12px;
	margin-top: 2px;
	color: red;
}
.section-container {
	max-width: 1440px;
	margin: 0 auto;
}
.m-auto {
	margin: 0 auto;
}
.cursor-pointer{
	cursor: pointer;
}