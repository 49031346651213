.menu-wrapper {
	.list-items {
		border: 1px solid #e5e5e5;
		background: #fff;
		li {
			padding: 10px;
			a {
				display: block;
				width: 100%;
				color: #1f1f1f;
				div {
					vertical-align: middle;
					padding-right: 10px;
				}
				svg {
					position: relative;
					top: 2px;
				}
			}
			&.active {
				background-color: rgba(248, 149, 158, 0.13);
				color: #f93d49;
				.img-content {
					svg {
						path {
							fill: #f93d49;
						}
					}
				}
			}
		}
	}
}
.main-wrapper {
	background: linear-gradient(
		4.82deg,
		rgba(242, 241, 241, 0.76) 3.78%,
		#ffffff 41.74%
	);
	border: 1px solid #dbdbdb;
	box-sizing: border-box;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
	border-radius: 4px 4px 0px 0px;
	padding: 20px 25px;
	.heading-title {
		h3 {
			font-size: 20px;
			line-height: 23px;
			color: #1f1f1f;
			font-weight: normal;
			padding-bottom: 5px;
		}
		h4 {
			color: #1f1f1f;
			opacity: 0.7;
		}
	}
}
input:read-only {
	background: #f5f5f5;
}
.main-container {
	max-width: calc(100vw - 230px);
	.wrapper {
		&.view {
			.form-group.pos-relative {
				.add-on {
					background: transparent;
					border: 0;
					line-height: 16px;
				}
				input[name="reference"] {
					padding-left: 24px !important;
				}
			}
			input,
			select {
				border: 0 !important;
				background: none !important;
				background-color: transparent;
				pointer-events: none;
				font-weight: 500;
			}
			ul.inline-info {
				li {
					&:first-child {
						padding-top: 5px;
					}
				}
			}
		}
		.main-title {
			background: linear-gradient(
				4.82deg,
				rgba(242, 241, 241, 0.76) 3.78%,
				#ffffff 41.74%
			);
			border: 1px solid #dbdbdb;
			box-sizing: border-box;
			box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
			border-radius: 4px 4px 0px 0px;
			padding: 15px 25px;
			h3 {
				font-size: 20px;
				line-height: 23px;
				color: #1f1f1f;
				font-weight: normal;
				padding-bottom: 5px;
			}
			p {
				color: #1f1f1f;
				opacity: 0.7;
				font-size: 14px;
			}
		}
	}
	.search-box {
		position: relative;
		input {
			border: 1px solid rgba(154, 157, 158, 0.58);
			box-sizing: border-box;
			border-radius: 2px;
			padding: 5px;
			transition: 0.3s ease all;
			&:focus {
				border-color: rgba(31, 31, 31, 0.44) !important;
				transition: 0.3s ease all;
			}
		}
		span {
			position: absolute;
			top: 0;
			right: 0;
			margin: 6px 10px 0;
		}
	}
	.btn-custom-square {
		padding: 6px 10px;
		font-weight: normal;
	}

	.table-container {
		border: 1px solid #dbdbdb;
		padding: 15px 25px;
		background: #fff;
		border-top: 0;
		table {
			width: 100%;
			th,
			td {
				width: 13%;
				text-align: left;
				font-size: 14px;
				color: #363636;
				padding: 10px 5px;
				&:nth-child(4) {
					width: 14%;
				}
				&:nth-child(3),
				&:nth-child(5) {
					width: 20%;
				}
				&:nth-child(6) {
					width: 14%;
				}
				&:nth-child(7) {
					width: 6%;
				}
			}
			thead {
				background: rgba(78, 78, 78, 0.1);
				display: table;
				width: calc(100% - 17px);
				th {
					font-weight: 500;
				}
			}
			tbody {
				tr {
					cursor: pointer;
					border-bottom: 1px solid rgba(0, 0, 0, 0.1);
					&:last-child {
						border-bottom: 0;
					}
					td,
					th {
						padding: 12px 5px;
						&:last-child {
							text-align: center;
						}
					}
				}
			}
		}
		&.services-table {
			th,
			td {
				width: 23% !important;
				&:nth-child(3) {
					width: 31% !important;
				}
			}
			th:last-child {
				text-align: center !important;
			}
		}
	}
}
.cd-panel {
	visibility: hidden;
	transition: visibility 0s 0.6s;
	&.isOpen {
		visibility: visible;
		transition: visibility 0s 0s;
		.cd-panel__container {
			transform: translate3d(0, 0, 0);
			transition-delay: 0s;
		}
	}
	.cd-panel__container {
		position: fixed;
		width: 50%;
		height: 100%;
		top: 0;
		transition: transform 0.3s 0.3s;
		right: 0;
		transform: translate3d(100%, 0, 0);
		box-shadow: 4px 0 15px 0 rgba(0, 0, 0, 0.5);
		z-index: 1;
		background-color: #fff;
		.header-content {
			box-shadow: 0px 1px 1px #e5e5e5;
			padding: 10px 25px;
			h3 {
				color: #211f1f;
				font-size: 18px;
				padding-right: 10px;
			}
			.btn-inline {
				button {
					vertical-align: middle;
					&.btn-edit-custom {
						background: #ffffff;
						border: 1px solid #869399;
						box-sizing: border-box;
						border-radius: 2px;
						color: #52545b;
						padding: 3px 5px;
						text-align: center;
					}
					&.btn-close {
						position: relative;
						top: 3px;
					}
				}
			}
		}
		.cd-panel__content {
			padding: 10px 25px;
			max-height: calc(100vh - 120px);
			overflow-y: auto;
		}
	}
}
.content-info {
	.title {
		letter-spacing: 0.06em;
		text-transform: uppercase;
		color: #1f1f1f;
		opacity: 0.7;
		font-weight: 600;
		padding: 10px 0 20px;
		font-size: 13px;
	}
	ul.inline-info {
		padding-bottom: 15px;
		li {
			width: 50%;
			display: inline-block;
			vertical-align: top;
			&:nth-child(2) {
				font-weight: 500;
				color: #363636;
			}
		}
	}
	div.line-divider {
		opacity: 0.1;
		border-bottom: 1px solid #000000;
		transform: matrix(1, 0, 0, 1, 0, 0);
	}
	table {
		width: 100%;
		text-align: left;
		&.card-type-detail,
		&.iata-detail,
		&.service-detail,
		&.message-detail {
			th,
			td {
				padding: 10px 10px;
				width: 22%;
				&:last-child {
					width: 12%;
				}
			}
			thead {
				background: rgba(78, 78, 78, 0.1);
				th {
					font-weight: 500;
				}
			}
			tbody {
				tr {
					border-bottom: 1px solid rgba(0, 0, 0, 0.1);
				}
			}
		}
		&.card-type-detail {
			th,
			td {
				&:last-child {
					width: 80% !important;
				}
			}
		}
		&.card-type-detail,
		&.service-detail {
			th,
			td {
				&:first-child {
					width: 34% !important;
				}
			}
		}
		&.message-detail {
			th,
			td {
				width: 50% !important;
			}
		}
	}
	&.add-form-section {
		background-color: #fff;
		padding: 15px 25px;
		border-top: 0 !important;
		border: 1px solid #dbdbdb;
	}
}
.footer-content {
	background: #f9f9f9;
	padding: 15px 25px;
	position: fixed;
	width: 100%;
	bottom: 0;
	.btn-square-red {
		background: #ffffff;
		border: 1px solid rgba(206, 84, 96, 0.6);
		box-sizing: border-box;
		border-radius: 2px;
		color: #ef4e58;
		padding: 5px 10px;
	}
}
.w-75 {
	min-width: 75px;
}
.add-on {
	position: absolute;
	padding: 8px;
	background: #e5e5e5;
	top: 0;
	height: 32px;
	border: 1px solid #bdbdbd;
	line-height: 14px;
}
.tab-content {
	position: relative;
	top: 1px;
	ul {
		li {
			display: inline-block;
			padding: 7px 20px;
			cursor: pointer;
			text-transform: uppercase;
			font-size: 13px;
			&.active {
				background-color: #fff;
				color: #f93d49;
				border: 1px solid #dbdbdb;
				border-bottom: 0;
				font-weight: bold;
				border-top-left-radius: 4px;
				border-top-right-radius: 4px;
				a {
					color: #f93d49;
				}
			}
			a {
				color: #000a30;
				padding: 7px 20px;
				display: block;
			}
		}
	}
}
.inner-title {
	font-size: 13px;
	letter-spacing: 0.06em;
	text-transform: uppercase;
	color: #1f1f1f;
	opacity: 0.7;
}

.list-content {
	ul {
		li {
			&:last-child {
				font-weight: 500;
				font-size: 14px;
				line-height: 16px;
				color: #363636;
				padding: 5px 0;
			}
		}
	}
}
.table-custom {
	th,
	td {
		&:nth-child(1) {
			padding-left: 15px !important;
			width: 30% !important;
		}
		&:nth-child(2),
		&:nth-child(3) {
			width: 20% !important;
		}
		&:nth-child(4) {
			width: 30% !important;
		}
	}
	tbody {
		td {
			&:nth-child(4) {
				padding-right: 17px !important;
			}
		}
	}
}
.table-common {
	thead {
		background: rgba(78, 78, 78, 0.1);
		th {
			font-weight: 500;
		}
	}
	tbody {
		tr {
			border-bottom: 1px solid rgba(0, 0, 0, 0.1);
		}
	}
	th,
	td {
		padding: 10px 10px;
	}
}
.table2X2 {
	th,
	td {
		word-break: break-word;
		&:first-child {
			width: 80% !important;
		}
		&:last-child {
			width: 20% !important;
			padding-right: 17px !important;
		}
	}
}
.table3X3 {
	th,
	td {
		width: 33% !important;
		&:last-child {
			width: 34% !important;
			padding-right: 17px !important;
			text-align: left !important;
		}
	}
}
.table4X4 {
	th,
	td {
		width: 30% !important;
		&:last-child {
			width: 10% !important;
			padding-right: 17px !important;
			text-align: left !important;
		}
	}
}
.table2X2 {
	.name {
		font-size: 14px;
		padding-bottom: 10px;
	}
	.value {
		font-size: 15px;
		font-weight: 500;
	}
}

.container-checkbox {
	position: relative;
	cursor: pointer;
	-webkit-user-select: none;
	user-select: none;
	box-sizing: border-box;
	padding: 6px 10px 6px 40px;
	border-radius: 2px;
}

.container-checkbox input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
}

.container-checkbox .checkmark {
	position: absolute;
	top: 0;
	left: 0;
	height: 18px;
	width: 18px;
	border: 1px solid #f93d49;
	margin: 6px 8px;
}

.container-checkbox:hover input ~ .checkmark {
	background-color: #fff;
}

.container-checkbox input:checked ~ .checkmark {
	background-color: #f93d49;
}

.container-checkbox .checkmark:after {
	content: "";
	position: absolute;
	display: none;
}

.container-checkbox input:checked ~ .checkmark:after {
	display: block;
}

.container-checkbox .checkmark:after {
	left: 6px;
	top: 1px;
	width: 6px;
	height: 11px;
	border: solid white;
	border-width: 0 2px 2px 0;
	transform: rotate(45deg);
}

.radio {
	position: relative;
	margin: 0 1rem 0 0;
	cursor: pointer;
	&:before {
		@include transition(transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75));
		@include transform(scale(0, 0));
		content: "";
		position: absolute;
		top: 0;
		left: 0.125rem;
		z-index: 1;
		width: 0.75rem;
		height: 0.75rem;
		background: #ef4e58;
		border-radius: 50%;
	}

	&:checked {
		&:before {
			transform: scale(1, 1) !important;
		}
	}

	&:after {
		content: "";
		position: absolute;
		top: -0.25rem;
		left: -0.125rem;
		width: 1.25rem;
		height: 1.25rem;
		background: #fff;
		border: 2px solid #ef4e58;
		border-radius: 50%;
	}
}
.fw-500 {
	font-weight: 500;
}
.txt-underline {
	text-decoration: underline;
}
.permission-wrap {
	border: 1px solid #dbdbdb;
	padding: 10px;
}
.permission-mapper {
	margin-bottom: 5px;
	i {
		font-size: 16px;
		color: #ef4e58;
	}
	span {
		font-weight: 500;
	}
}

.user-icon {
	i {
		color: #0077bf;
		font-size: 45px;
	}
}
.main-wrapper {
	.content-head {
		background: linear-gradient(
			4.82deg,
			rgba(242, 241, 241, 0.76) 3.78%,
			#ffffff 41.74%
		);
		border: 1px solid #dbdbdb;
		box-sizing: border-box;
		border-radius: 4px 4px 0px 0px;
		width: 100%;
		padding: 15px 20px 0;
	}
	.section-content {
		padding: 30px;
	}
	.welcome-text {
		h3 {
			font-size: 20px;
			color: #1f1f1f;
			font-weight: normal;
			span {
				font-weight: 500;
			}
		}
	}
	.image-content {
		box-sizing: border-box;
		padding: 15px 20px;
		img.nf-logo {
			height: 70px;
		}
	}
	.app-content {
		font-size: 16px;
		padding: 20px;
	}
	.app-image-content {
		padding: 20px;
		background: #f5f5f5;
		border: 1px solid #ddd;
		text-align: center;
		margin: 30px;
		width: 180px;
		height: 140px;
		position: relative;
		cursor: pointer;
		.title {
			font-size: 16px;
			color: #1f1f1f;
			font-weight: 500;
			position: absolute;
			bottom: 15px;
			margin: auto;
			text-align: center;
			left: 0;
			right: 0;
		}
		svg {
			path {
				fill: #ef4e58;
			}
		}
	}
	.description-content {
		padding: 20px;
		line-height: 25px;
		color: #1f1f1f;
		opacity: 0.7;
	}
	.secondary-title {
		color: #363636;
	}
}
.line-h-40 {
	line-height: 40px;
}
.border-round {
	border: 1px solid #f0f0f0;
}

.authenticator-wrap{
	text-align: left;
    margin: 8px;
    font-size: 14px;
    font-weight: 500;
}
.expired-otp{
	display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
	height: 400px;
}
