.header {
	height: 50px;
	position: relative;
	z-index: 1;
	background: #ffffff;
	box-shadow: 0px 1px 1px #e5e5e5;
	&-menu {
		margin-top: 5px;
		&__list {
			position: relative;
			i {
				display: inline-block;
				cursor: pointer;
			}
		}
	}
	&-left {
		width: 230px;
		min-width: 230px;
		border-right: 1px solid rgba($black, 0.1);
	}
	&-right {
		padding: 0 30px;
	}

	.brand-logo {
		cursor: pointer;
		width: 145px;
		height: 30px;
		object-fit: contain;
	}
	.user-type {
		// margin: 15px;
		// padding-left: 10px;
		// border-left: 1px solid rgba($teritary-text-color, 0.5);
		p {
			font-weight: 500;
			font-size: 16px;
			white-space: nowrap;
			position: relative;
			top: 2px;
		}
	}
	.dropdown {
		background: #ffffff;
		box-shadow: 0px 2px 28px rgba(174, 163, 163, 0.25);
		// box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
		border-radius: 4px;
		position: absolute;
		left: -20px;
		top: 35px;
		&::after {
			content: "";
			position: absolute;
			top: -19px;
			left: 22px;
			display: inline-block;
			border: 10px solid white;
			border-right: 10px solid transparent;
			border-left: 10px solid transparent;
			border-top: 10px solid transparent;
		}
	}
	.applications-dropdown {
		padding: 30px 20px;
		h6 {
			font-weight: 500;
			font-size: 16px;
			line-height: 19px;
		}
		.menu-item {
			width: 85px;
			height: 75px;
			background: $background-color;
			border: 1px solid $background-color;
			border-radius: 2px;
			text-align: center;
			padding: 8px;
			cursor: pointer;
			transition: all 0.3s ease;
			.path {
				fill: $secondary-brand-color;
			}
			&.active,
			&:hover {
				background: $secondary-brand-color;
				.path {
					fill: $white;
				}
				p {
					color: $white;
				}
			}
			&:not(:last-child) {
				margin-right: 10px;
			}
			p {
				font-weight: 500;
				font-size: 11px;
				line-height: 13px;
				text-align: center;
			}
		}
	}
	.profile-dropdown {
		width: 240px;
		right: -10px;
		left: initial;
		&::after {
			right: 10px;
			left: initial;
		}
		.username {
			font-size: 18px;
			line-height: 21px;
			padding: 20px 20px 10px 20px;
			border-bottom: 1px solid rgba($teritary-text-color, 0.3);
			p {
				font-size: 13px;
				line-height: 16px;
				font-weight: normal;
				color: $teritary-text-color;
			}
		}
		.profile-menu {
			padding: 15px 20px;
			border-bottom: 1px solid rgba($teritary-text-color, 0.3);
			a {
				display: block;
				font-weight: 500;
				font-size: 14px;
				line-height: 16px;
				color: rgba($primary-text-color, 0.8);
			}
		}
		.last-login {
			padding: 15px 20px;
			font-size: 12px;
			line-height: 14px;
			color: $teritary-text-color;
		}
	}
	.agency-name {
		color: $teritary-text-color;
	}
}
